
/**
 *Sub project view or the main view if the user is not admin
 * All  in this table are sub projects.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

import Project from "@/model/Project";
import UserAccess from "@/model/User/UserAccess";
@Component({
  name: "SubProjectView",
  computed: {
    ...mapGetters(["isUserAdmin", "isUserProjectManager", "isConstructionManager", "getUserAccess"]),
    ...mapGetters("projects", {
      GetSubProjectsList: "GetSubProjectsList",
      archiveSubProjectsList: "GetAllArchivedSubProjects"
    })
  }
})
export default class SubProjectView extends Vue {
  @Prop({ type: Array }) subProjects!: Array<Project>;
  @Prop({ type: Boolean, default: false }) isArchiveList!: boolean;

  GetSubProjectsList!: Function;
  archiveSubProjectsList!: Function;
  //Vuex
  readonly getUserAccess!: UserAccess;

  created() {
    this.subProjects.sort((a, b) =>
      a.directionPositionStart && b.directionPositionStart
        ? a.directionPositionStart > b.directionPositionStart
          ? 1
          : a.directionPositionStart < b.directionPositionStart
          ? -1
          : 0
        : 0
    );
  }
  subProjectLabels: Array<object> = [
    {
      key: "directionNameEnd",
      sortable: true,
      label: this.translateTableLabel("directionNameEnd")
    },
    {
      key: "name",
      sortable: true,
      label: this.translateTableLabel("name")
    },
    {
      key: "directionPositionStart",
      sortable: true,
      label: this.translateTableLabel("directionPositionStart")
    },
    {
      key: "directionPositionEnd",
      sortable: true,
      label: this.translateTableLabel("directionPositionEnd")
    },
    {
      key: "type",
      sortable: true,
      label: this.translateTableLabel("type")
    },
    {
      key: "status",
      sortable: true,
      label: this.translateTableLabel("status")
    },
    {
      key: "dateStart",
      sortable: true,
      label: this.translateTableLabel("dateStart")
    },
    {
      key: "dateEnd",
      sortable: true,
      label: this.translateTableLabel("dateEnd")
    },
    {
      key: "actions",
      label: this.translateTableLabel("actions")
    }
  ];
  translateTableLabel(label: string) {
    return this.$t(`projects.tableLabel.${label}`);
  }

  handleOpen(row: any) {
    //row.toggleDetails();
    this.$emit("handle-open", row);
  }
}
